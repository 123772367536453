.error-pop-up{
    display: flex;
    overflow: hidden;
    justify-content: center;
    position: absolute;
    align-items: center;
    flex-direction: column;
    height: 100%;        
    width: 100%;
    z-index: 100;
}
.errdiv {
    width: 25em;
    height: 25em;
    background-color: rgba(255, 69, 0, 0.8);
    border-radius: 1.5em;
    padding: 1em;
}
.close{
    float: right;
    background-color: red;
    border-radius: 6px;
}
.error-content-div{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.errorMessage{
    color: black;
    font-weight: 800;
    font-size: 18px;
    
}