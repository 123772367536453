.card-container {
    height: 17em;
    margin-top: 2vh;
    width: 16em;
    margin-bottom: 3vh;
    padding: 6px;
    perspective: 1000px;
   
}
.card {
    display: flex;
    width: 100%;
    height: 100%;
    font-weight: 500;
    padding: 5px;
    color: white;
    position: relative;
    flex-direction: column;
    transition: transform 1s;
    transform-style: preserve-3d;
    animation: shake 200ms;
}
@keyframes shake {
    0%{
        margin-left: 20em;
    }
    100%{
        margin-left: 0em;
    }
}
.card-container:hover .card {
    transform: rotateY(180deg);
  }
.card-front,
.card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}
.card-front {
    background: linear-gradient(50deg,red,purple);
    border-radius: 20px;
    color: white;
   
}
.card-back {
    border-radius: 20px;
    background: linear-gradient(150deg,purple,red);
    color: #eeeeee;
    transform: rotateY(180deg);
}
.card-item {
    flex: 1;
}
.row {
    display: flex;
    margin-top: 2px;
} 
.info {
    font-family: sans-serif;
    font-size: 15px;
    font-weight: 600;
    /* color: rgba(255,255,255,0.8); */
    color: limegreen;
    animation: col 6s;
}
table {
    margin: 0 auto;
}
@keyframes col {
    0%{
        translate: 20px;
    }
    25%{
        translate: 0px;
    }
    50%{
        translate: 10px;
    }
    50%{
        translate: 0px;
    }
}