.Team {
    display: flex;
    /* overflow: hidden; */
    /* overflow-x: scroll; */
    justify-content: center;
    position: absolute;
    /* margin-top: 2em; */
    align-items: center;
    flex-direction: column;
    /* place-content: center; */
    height: 100vh;
    width: 100vw;
    /* align-content: center; */
}
.searchfield {
    width: 30em;
    text-align: center;
    margin-bottom: 1em;
}
.Manager-div{
    background-color: peachpuff;
    color: black;
    width: 20em;
    height: 25em;
    padding: 0.5em;
    border-radius: 1em;
}