/* .display-wrapper {

    margin-top: 4px;
    background-color: black; 
    height: 150px;
} */

#search-display {
    background-color: rgba(255, 239, 213,0.8);
    color: black;
    min-height: min-content;
    max-height: 80px;
    /* margin-bottom: 21vh; */
    width: 30vw;
    overflow-y: scroll;
    border-radius: 15px 15px 4px 4px;
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    /* top: 38%; */
    /* bottom: 30.3%; */
    /* bottom: 100vw/100vh; */
    z-index: 80;
}
.button {
    background: none;
    cursor: pointer;
    border: 0;
    font-weight: 550;
    font-size: 18px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.button:hover {
    color: red;
}